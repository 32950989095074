<template>
  <b-modal
    id="bulkEditor"
    ref="bulkEditorModal"
    :ok-title="`${unassignedLeads ? 'Assign' : 'Apply changes to'} ${salesRecords.length} deals`"
    title="Deals Bulk Editor"
    :busy="busy"
    @ok.prevent="updateLeads"
    :ok-disabled="updatingLeads || formEmpty"
    :cancel-disabled="updatingLeads"
  >
    <div class="mb-3">
      <b-button
        class="reducer-btn border float-right"
        variant="light"
        size="sm"
        @click="clear"
        :disabled="updatingLeads"
        >Clear selection</b-button
      >
    </div>
    <b-form-group
      label="CPM"
      label-for="cpm"
      :state="unassignedLeads && cpmId !== null"
      :invalid-feedback="unassignedLeads ? 'Required' : null"
    >
      <b-select
        id="cpm"
        v-model="cpmId"
        :options="cpms"
        text-field="email"
        value-field="userId"
        placeholder="Select CPM"
      >
        <template #first>
          <b-form-select-option :value="null">Select CPM</b-form-select-option>
        </template>
      </b-select>
    </b-form-group>
    <b-form-group
      label="APM"
      label-for="apms"
      :description="salesRecords ? null : 'If left blank, current user will be assigned as an APM'"
    >
      <b-select
        id="apms"
        v-model="apmId"
        :options="apms"
        text-field="email"
        value-field="userId"
        placeholder="Select Ops"
      >
        <template #first>
          <b-form-select-option :value="null">Select APMs</b-form-select-option>
        </template>
      </b-select>
    </b-form-group>
    <b-form-group label="Ops" label-for="ops">
      <b-select
        id="ops"
        v-model="opsId"
        :options="ops"
        text-field="email"
        value-field="userId"
        placeholder="Select Ops"
      >
        <template #first>
          <b-form-select-option :value="null">Select Ops</b-form-select-option>
        </template>
      </b-select>
    </b-form-group>
    <b-form-group label="Project Name" label-for="project">
      <b-form-input v-model="project" />
    </b-form-group>
    <b-form-group
      label="Stage"
      :state="unassignedLeads && stage !== null"
      :invalid-feedback="unassignedLeads ? 'Required' : null"
    >
      <div class="d-flex">
        <sales-record-stage-select
          :stage="stage"
          @change-stage="stage = $event"
          @change-closed-lost="({ reason }) => (closedLostReason = reason)"
          no-mandatory-date
        />
        <closed-lost-reason-select
          v-if="stage === 'CLOSED_LOST'"
          class="ml-2"
          :closed-lost-reason="closedLostReason"
          @change-reason="closedLostReason = $event"
        />
      </div>
    </b-form-group>
    <div>
      <b-check id="closedWon" v-model="closedWonMeters" :value="[]"
        >Clear closed won meters</b-check
      >
      <b-check id="closedLost" v-model="closedLostMeters" :value="[]"
        >Clear closed lost meters</b-check
      >
      <b-check id="clearNextAction" v-model="nextAction" :value="null"
        >Clear date of next action</b-check
      >
    </div>
  </b-modal>
</template>

<script>
import ClosedLostReasonSelect from "./ClosedLostReasonSelect.vue";
import SalesRecordStageSelect from "./SalesRecordStageSelect.vue";
export default {
  components: { SalesRecordStageSelect, ClosedLostReasonSelect },
  name: "BulkEditor",
  props: {
    salesRecords: {
      type: Array,
      required: true
    },
    busy: {
      type: Boolean,
      required: true
    },
    cpms: {
      type: Array,
      required: true
    },
    ops: {
      type: Array,
      required: true
    },
    apms: {
      type: Array,
      required: true
    },
    unassignedLeads: {
      type: Boolean
    }
  },
  data() {
    return {
      cpmId: null,
      opsId: null,
      stage: null,
      apmId: null,
      closedLostReason: null,
      closedWonMeters: null,
      closedLostMeters: null,
      updatingLeads: false,
      nextAction: true,
      project: null
    };
  },
  computed: {
    formEmpty() {
      return (
        !this.cpmId &&
        !this.opsId &&
        !this.stage &&
        !this.apmId &&
        !this.closedLostReason &&
        !this.closedWonMeters &&
        !this.closedLostMeters &&
        this.nextAction &&
        !this.project
      );
    }
  },
  methods: {
    show() {
      this.$refs.bulkEditorModal.show();
    },
    hide() {
      this.clear();
      this.$refs.bulkEditorModal.hide();
    },
    clear() {
      this.opsId = null;
      this.cpmId = null;
      this.apmId = null;
      this.stage = null;
      this.closedLostReason = null;
      this.updatingLeads = false;
      this.closedWonMeters = null;
      this.closedLostMeters = null;
      this.nextAction = true;
      this.project = null;
    },
    updateLeads() {
      this.updatingLeads = true;
      const updated = this.salesRecords.slice();
      updated.forEach(s => {
        if (s.name) {
          s.organisationName = s.name;
        }
        if (this.opsId) {
          s.opsId = this.opsId;
          s.opsEmail = this.ops.find(c => c.opsId === this.opsId).email;
        }
        if (this.cpmId) {
          s.cpmId = this.cpmId;
          s.cpmEmail = this.cpms.find(c => c.cpmId === this.cpmId).email;
        }
        if (this.apmId) {
          s.apmId = this.apmId;
          s.apmEmail = this.apms.find(c => c.apmId === this.apmId).email;
        }
        if (this.stage) {
          s.stage = this.stage;
        }
        if (this.closedLostReason) {
          s.closedLostReason = this.closedLostReason;
        }
        if (this.closedWonMeters) {
          s.closedWonMeters = this.closedWonMeters;
        }
        if (this.closedLostMeters) {
          s.closedLostMeters = this.closedLostMeters;
        }
        if (!this.nextAction) {
          s.nextActionDate = null;
        }
        if (this.project) {
          s.project = this.project;
        }
      });
      this.$emit("update-deals", updated);
    }
  }
};
</script>

<style lang="scss">
@import "@/styles/common.scss";
#bulkEditor {
  .reducer-badge {
    padding: 7px;
    font-size: 14px;
  }
}
</style>
