<template>
  <b-container id="sales">
    <vue-headful :title="title + ($appName ? ' | ' + $appName : '')" />
    <h1>{{ title }}</h1>

    <b-tabs v-model="activeTab">
      <b-tab title="Deals" lazy active>
        <SalesRecordsTab
          ref="salesRecordsTab"
          :sales-records="salesRecords"
          :busy="salesRecordsBusy"
          :cpms="cpms"
          :ops="ops"
          :users-busy="usersBusy"
          :current-user-id="currentUserId"
          :table-busy="updatingSalesRecords || (salesRecordsBusy && salesRecords.length > 0)"
          @fetch-sales-records="fetchSalesRecords"
          @delete-sales-record="deleteSalesRecord"
          @update-url="updateUrl"
          @bulk-edit="openBulkEditor"
        />
      </b-tab>
      <b-tab title="Awaitin' Bait" lazy>
        <SalesOrganisationsTab
          ref="organisationsTab"
          :organisations="organisations"
          :busy="organisationsBusy"
          :cpms="cpms"
          :apms="apms"
          :users-busy="usersBusy"
          @fetch-organisations="fetchOrganisations"
          @org-assigned="organisationAssigned"
          @bulk-edit="openBulkEditor"
          :table-busy="updatingSalesRecords || (salesRecordsBusy && salesRecords.length > 0)"
        />
      </b-tab>
    </b-tabs>
    <BulkEditor
      ref="bulkEditor"
      :salesRecords="bulkEditRecords"
      :cpms="cpms"
      :ops="ops"
      :apms="apms"
      :busy="salesRecordsBusy"
      :unassigned-deals="activeTab === 1"
      @update-deals="updateSalesRecords"
    />
  </b-container>
</template>

<script>
import { EventBus } from "@/components/eventbus";
import ApiHelper from "@/helper/apihelper";
import TypeHelper from "@/helper/typehelper";
import CognitoAuth from "@/cognito/cognitoauth";
import TokenHelper from "@/cognito/tokenhelper";
import SalesOrganisationsTab from "./SalesOrganisationsTab";
import SalesRecordsTab from "./SalesRecordsTab";
import FormatHelper from "@/helper/formathelper";
import BulkEditor from "../../../components/sales/BulkEditor.vue";

export default {
  name: "Sales",
  components: {
    SalesOrganisationsTab,
    SalesRecordsTab,
    BulkEditor
  },
  data() {
    return {
      title: "Sales Bible",
      currentUserId: "",
      activeTab: 0,

      organisationsBusy: false,
      salesRecordsBusy: false,
      usersBusy: false,

      organisations: [],
      salesRecords: [],
      cpms: [],
      ops: [],
      apms: [],

      bulkEditRecords: [],
      updatingSalesRecords: false
    };
  },
  created() {
    this.fetchCpmUsers();
    this.fetchOpsUsers();
    this.fetchApmUsers();
    this.getCurrentUserId();
  },
  methods: {
    async fetchOrganisations(filter) {
      this.organisationsBusy = true;
      const client = await ApiHelper.http();
      await client
        .get(`${ApiHelper.endPoint()}organisations${this.getOrganisationParams(filter)}`)
        .then(response => {
          console.log("Unassigned organisations", response);
          this.organisations = response.data.organisations;
        })
        .catch(e => {
          console.error(e);
          this.showMessage("There was an issue fetching organisation data.", "warning");
        })
        .finally(() => (this.organisationsBusy = false));
    },

    async fetchSalesRecords(filter) {
      this.salesRecordsBusy = true;
      const client = await ApiHelper.http();
      await client
        .post(`${ApiHelper.endPoint()}sales/records/query`, this.getSalesRecordParams(filter))
        .then(response => {
          console.log("Sales records:", response);
          this.salesRecords = response.data.salesRecords;
        })
        .catch(e => {
          console.error(e);
          this.showMessage("There was an issue fetching deals data.", "warning");
        })
        .finally(() => (this.salesRecordsBusy = false));
    },

    async fetchCpmUsers() {
      this.usersBusy = true;
      const client = await ApiHelper.http();
      await client
        .get(`${ApiHelper.endPoint()}users?cpmsOnly=true`)
        .then(response => {
          console.log("CPMs:", response);
          this.cpms = response.data.users;
        })
        .catch(e => {
          console.error(e);
          this.showMessage("There was an issue fetching user data.", "warning");
        })
        .finally(() => (this.usersBusy = false));
    },

    async fetchOpsUsers() {
      this.usersBusy = true;
      const client = await ApiHelper.http();
      await client
        .get(`${ApiHelper.endPoint()}users?opsOnly=true`)
        .then(response => {
          console.log("Ops:", response);
          this.ops = response.data.users;
        })
        .catch(e => {
          console.error(e);
          this.showMessage("There was an issue fetching user data.", "warning");
        })
        .finally(() => (this.usersBusy = false));
    },

    async fetchApmUsers() {
      this.usersBusy = true;
      const client = await ApiHelper.http();
      await client
        .get(`${ApiHelper.endPoint()}users?apmsOnly=true`)
        .then(response => {
          console.log("APMs:", response);
          this.apms = response.data.users;
        })
        .catch(e => {
          console.error(e);
          this.showMessage("There was an issue fetching user data.", "warning");
        })
        .finally(() => (this.usersBusy = false));
    },

    async updateSalesRecords(salesRecords) {
      this.updatingSalesRecords = true;
      const client = await ApiHelper.http();
      await client
        .post(`${ApiHelper.endPoint()}sales/records`, {
          salesRecords: salesRecords
        })
        .then(() => {
          if (this.activeTab === 0) {
            this.$refs.salesRecordsTab.fetchSalesRecords();
          } else {
            salesRecords.forEach(s => this.organisationAssigned(s.organisationId));
          }
          this.showMessage("Deals updated successfully.", "success");
          this.$refs.bulkEditor.hide();
        })
        .catch(e => {
          console.error(e);
          if (e.response.status == 409) {
            this.showMessage(
              "Someone else has updated one of the deals. Please refresh the page to see the latest version.",
              "warning"
            );
          } else {
            this.showMessage("There was an issue updating deals.", "warning");
          }
        })
        .finally(() => (this.updatingSalesRecords = false));
    },

    async deleteSalesRecord(organisationId) {
      const client = await ApiHelper.http();
      await client
        .delete(`${ApiHelper.endPoint()}sales/${organisationId}/records`)
        .then(() => {
          this.showMessage("Deal deleted successfully.", "success");
          this.salesRecords = this.salesRecords.filter(i => i.organisationId !== organisationId);
        })
        .catch(e => {
          console.error(e);
          this.showMessage("There was an issue deleting the deal.", "warning");
        });
    },

    organisationAssigned(organisationId) {
      this.organisations = this.organisations.filter(i => i.organisationId !== organisationId);
      this.salesRecords = [];
    },

    async getCurrentUserId() {
      var token = await CognitoAuth.getCurrentUserAccessToken();
      this.currentUserId = TokenHelper.parseJwtToken(token).sub;
    },

    getOrganisationParams(filter) {
      let params = ApiHelper.appendParam(params, "query", filter.queryInput);
      params = ApiHelper.appendParam(params, "summarised", true);
      params = ApiHelper.appendParam(params, "excludeOrgsWithSalesRecords", true);
      params = ApiHelper.appendParam(params, "includeSavingsData", true);
      if (filter.startDate) {
        params = ApiHelper.appendParam(params, "startLastExtractMonth", filter.startDate);
      }
      if (filter.endDate) {
        params = ApiHelper.appendParam(params, "endLastExtractMonth", filter.endDate);
      }
      if (filter.cedStart != null) {
        params = ApiHelper.appendParam(
          params,
          "cedStart",
          FormatHelper.formatDateToLocalDateTime(filter.cedStart)
        );
      }
      if (filter.cedEnd != null) {
        params = ApiHelper.appendParam(
          params,
          "cedEnd",
          FormatHelper.formatDateToLocalDateTime(filter.cedEnd, false)
        );
      }
      return params;
    },

    getSalesRecordParams(filter) {
      let params = { includeLatestSavings: true };
      if (filter.cpmId) {
        params.cpmId = filter.cpmId;
      }
      if (filter.apmId) {
        params.apmId = filter.apmId;
      }
      if (filter.opsId) {
        params.opsId = filter.opsId;
      }
      if (filter.minCommission != null) {
        params.minCommission = filter.minCommission;
      }
      if (filter.maxCommission != null) {
        params.maxCommission = filter.maxCommission;
      }
      if (filter.minSavings != null) {
        params.minSavings = filter.minSavings;
      }

      if (filter.maxSavings != null) {
        params.maxSavings = filter.maxSavings;
      }
      if (filter.reportSharedStart != null) {
        params.reportSharedStart = FormatHelper.formatDateToLocalDateTime(filter.reportSharedStart);
      }
      if (filter.reportSharedEnd != null) {
        params.reportSharedEnd = FormatHelper.formatDateToLocalDateTime(filter.reportSharedEnd);
      }
      if (filter.cedStart != null) {
        params.cedStart = FormatHelper.formatDateToLocalDateTime(filter.cedStart);
      }
      if (filter.cedEnd != null) {
        params.cedEnd = FormatHelper.formatDateToLocalDateTime(filter.cedEnd);
      }
      if (filter.nextActionStart != null) {
        params.nextActionStart = FormatHelper.formatDateToLocalDateTime(filter.nextActionStart);
      }
      if (filter.nextActionEnd != null) {
        params.nextActionEnd = FormatHelper.formatDateToLocalDateTime(filter.nextActionEnd);
      }
      if (filter.query != null && filter.query.trim() !== "") {
        params.query = filter.query.trim();
      }
      if (filter.areasWithCommission.length) {
        params.areasWithCommission = filter.areasWithCommission;
      }
      if (filter.excludeStages) {
        params.stages = TypeHelper.salesRecordStages.filter(s => !filter.excludeStages.includes(s));
      }
      if (filter.includeCommission) {
        params.includeCommission = filter.includeCommission;
      }
      if (filter.includeTotalAq) {
        params.includeTotalAq = filter.includeTotalAq;
      }
      if (filter.includeSoonestCed) {
        params.includeSoonestCed = filter.includeSoonestCed;
      }
      if (filter.organisationIds) {
        params.organisationIds = filter.organisationIds;
      }
      return params;
    },

    updateUrl() {
      this.$router.query = {};
    },

    showMessage(message, variant) {
      EventBus.$emit("show-toast", { message: message, variant: variant });
    },

    openBulkEditor(selected) {
      this.bulkEditRecords = selected;
      this.$refs.bulkEditor.show();
    }
  }
};
</script>

<style lang="scss">
#sales {
  @import "@/styles/common.scss";
  @include badge-styles;
  max-width: 1550px !important;
  th,
  td {
    text-align: center;
  }
  .reducer-filter {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .multiselect__tag {
    font-size: 13px;
  }
  tr {
    cursor: unset;
  }
  .spinner-grow {
    position: absolute;
    left: 50%;
    top: 12rem;
  }
  .date-selection:hover {
    .date-btns {
      z-index: 999;
      padding: 0.5rem;
      position: absolute;
      background: white;
      display: flex !important;
      box-shadow: $light-shadow;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }
}
</style>
