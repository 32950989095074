<template>
  <b-modal
    id="assign-cpm-modal"
    v-model="visible"
    hide-footer
    :title="`Assign CPM to ${org ? org.name : 'organisation'}`"
    @hide="resetCpmModal"
  >
    <b-form @submit.prevent="saveData">
      <b-form-group label="CPM Email*" label-for="cpm">
        <b-select
          id="cpm"
          v-model="cpmId"
          :options="cpms"
          text-field="email"
          value-field="userId"
          required
          :disabled="usersBusy || busy || salesRecordCreated"
        />
      </b-form-group>

      <b-form-group label="APM Email" label-for="apm">
        <b-select
          id="apm"
          v-model="apmId"
          :options="apms"
          text-field="email"
          value-field="userId"
          required
          :disabled="usersBusy || busy || salesRecordCreated"
        />
      </b-form-group>

      <b-form-group label="Deal Stage*" label-for="stage">
        <b-select
          id="stage"
          v-model="stage"
          :options="salesRecordStagesOptions"
          :disabled="busy || salesRecordCreated"
        />
      </b-form-group>

      <div v-if="salesRecordCreated">
        <b-alert variant="success" show class="m-0">
          <b
            >CPM assigned to organisation.
            <b-link
              target="_blank"
              :to="{
                name: 'sales-record',
                params: { organisationId: org.organisationId }
              }"
              >View sales record</b-link
            >
          </b>
        </b-alert>
      </div>
      <b-button v-else type="submit" :disabled="busy">Submit</b-button>
    </b-form>
  </b-modal>
</template>

<script>
import FormatHelper from "@/helper/formathelper";
import Busy from "@/components/Busy";
import ApiHelper from "@/helper/apihelper";
import TypeHelper from "@/helper/typehelper";
import { EventBus } from "@/components/eventbus";

export default {
  name: "AssignCpmModal",
  props: {
    cpms: {
      type: Array,
      required: true
    },
    apms: {
      type: Array,
      required: true
    },
    usersBusy: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      salesRecordStagesOptions: this.getSalesRecordStagesOptions(),
      visible: false,
      cpmId: null,
      apmId: null,
      org: null,
      busy: false,
      stage: "NEW_LEAD",
      salesRecordCreated: false,
      contact: {
        firstName: null,
        lastName: null,
        email: null,
        landlineNumber: null,
        mobileNumber: null,
        note: null
      }
    };
  },
  methods: {
    show(org) {
      if (org) {
        this.org = org;
        this.visible = true;
      }
    },

    saveData() {
      this.createSalesRecord();
      this.saveNote();
    },

    async createSalesRecord() {
      this.busy = true;
      let cpm = this.cpms.find(cpm => cpm.userId == this.cpmId);
      let apm = this.apms.find(apm => apm.userId == this.apmId);

      const client = await ApiHelper.http();
      await client
        .post(`${ApiHelper.endPoint()}sales/records`, {
          salesRecords: [
            {
              organisationId: this.org.organisationId,
              organisationName: this.org.name,
              firstUserEmail: this.org.firstUserEmail,
              firstUserId: this.org.firstUserId,
              cpmId: cpm.userId,
              cpmEmail: cpm.email,
              apmId: apm.userId,
              apmEmail: apm.email,
              stage: this.stage
            }
          ]
        })
        .then(() => {
          this.salesRecordCreated = true;
          this.showMessage("Sales record created successfully.", "success");
          this.$emit("org-assigned", this.org.organisationId);
        })
        .catch(e => {
          console.error(e);
          this.showMessage("There was an issue creating sales record.", "warning");
        })
        .finally(() => (this.busy = false));
    },

    async saveNote(note) {
      let text = this.contactToText();
      if (text) {
        const client = await ApiHelper.http();
        await client
          .post(`${ApiHelper.endPoint()}organisations/notes`, {
            note: {
              organisationId: this.org.organisationId,
              text: text
            }
          })
          .then(() => {})
          .catch(e => {
            console.error(e);
            this.showMessage("There was an issue saving note.", "warning");
          });
      }
    },

    contactToText() {
      let text = "";
      Object.entries(this.contact).forEach(([key, value]) => {
        if (value) {
          text += `${FormatHelper.formatCamelCaseToTitleCase(key)}: ${value}\n`;
        }
      });
      return text;
    },

    getSalesRecordStagesOptions() {
      return TypeHelper.salesRecordStages.map(stage => {
        return {
          text: TypeHelper.formatSalesRecordStage(stage),
          value: stage
        };
      });
    },

    resetCpmModal() {
      this.cpmId = null;
      this.apmId = null;
      this.stage = "NEW_LEAD";
      this.org = null;
      this.busy = false;
      this.salesRecordCreated = false;
    },

    showMessage(message, variant) {
      EventBus.$emit("show-toast", { message: message, variant: variant });
    }
  }
};
</script>

<style lang="scss" scoped></style>
