<template>
  <b-dropdown
    variant="link"
    toggle-class="text-decoration-none p-0"
    no-caret
    class="stage-dropdown"
    :disabled="disabled"
  >
    <template #button-content>
      <b-badge :class="`selection-badge reducer-badge stage-colour-${stageColour(stage)}`">
        {{ displayStage(stage)
        }}<font-awesome-icon v-if="!disabled" :icon="['fas', 'chevron-down']" />
      </b-badge>
    </template>
    <b-dropdown-item
      v-for="r in salesRecordStagesOptions"
      :key="r.value"
      @click="changeStage(r.value)"
    >
      {{ r.text }}
    </b-dropdown-item>
    <b-modal
      ref="closedLostSelect"
      hide-header
      hide-footer
      size="sm"
      no-close-on-backdrop
      no-close-on-esc
    >
      <div class="reducer-filter">
        <label>CLOSED LOST REASON AND DATE</label>
        <b-row class="filter-border align-items-end">
          <div class="px-2">
            <ClosedLostReasonSelect
              class="mb-2"
              :closed-lost-reason="closedLost.reason"
              @change-reason="changeReason"
            />
            <date-input v-if="!noMandatoryDate" size="sm" @change="closedLost.date = $event" />
          </div>
          <div>
            <b-button
              v-if="!noMandatoryDate"
              size="sm"
              class="mt-2 "
              variant="primary"
              @click="changeClosedLostDate"
              >Save</b-button
            >
          </div>
        </b-row>
      </div>
    </b-modal>
    <b-modal
      ref="closedWonSelect"
      hide-footer
      hide-header
      size="sm"
      no-close-on-backdrop
      no-close-on-esc
    >
      <div class="reducer-filter">
        <label>CLOSED WON DATE</label>
        <b-row class="filter-border">
          <div class="px-2">
            <date-input size="sm" @change="closedWonDate = $event" />
          </div>
          <b-button @click="changeClosedWonDate" size="sm" variant="primary">Save</b-button>
        </b-row>
      </div>
    </b-modal>
  </b-dropdown>
</template>

<script>
import TypeHelper from "@/helper/typehelper";
import ClosedLostReasonSelect from "./ClosedLostReasonSelect";
import DateInput from "../savingseditor/dealform/parts/inputs/DateInput";

export default {
  name: "SalesRecordStageSelect",
  components: {
    ClosedLostReasonSelect,
    DateInput
  },
  props: {
    disabled: Boolean,
    stage: {
      type: String,
      required: false
    },
    closedLostReason: {
      type: String,
      required: false
    },
    noMandatoryDate: Boolean
  },
  data() {
    return {
      salesRecordStageOptions: this.setSalesRecordStagesOptions(),
      closedLost: {
        reason: null,
        date: null
      },
      closedWonDate: null
    };
  },
  mounted() {
    this.closedLost = {
      reason: this.closedLostReason,
      date: null
    };
    this.closedWonDate = null;
  },
  methods: {
    displayStage(stage) {
      if (!stage) return "Select stage";
      return TypeHelper.formatSalesRecordStage(stage);
    },
    stageColour(stage) {
      if (!stage) return "";
      return TypeHelper.salesRecordStageColourMap[stage];
    },
    setSalesRecordStagesOptions() {
      this.salesRecordStagesOptions = TypeHelper.salesRecordStages.map(stage => ({
        text: TypeHelper.formatSalesRecordStage(stage),
        value: stage
      }));
    },
    changeStage(stage) {
      if (this.stage !== stage) {
        this.$emit("change-stage", stage);
      }
      if (stage === "CLOSED_LOST") {
        this.$refs.closedLostSelect.show();
      }
      if (stage === "CLOSED_WON" && !this.noMandatoryDate) {
        this.$refs.closedWonSelect.show();
      }
    },
    changeReason(reason) {
      this.closedLost.reason = reason;
      if (this.noMandatoryDate) {
        this.$emit("change-closed-lost", { reason: reason, date: this.closedLost.date });
        this.$refs.closedLostSelect.hide();
      }
    },
    changeClosedLostDate() {
      if (this.closedLost.reason) {
        this.$emit("change-closed-lost", this.closedLost);
        this.$refs.closedLostSelect.hide();
      }
    },
    changeClosedWonDate() {
      this.$emit("change-closed-won", { date: this.closedWonDate });
      this.$refs.closedWonSelect.hide();
    }
  }
};
</script>

<style lang="scss">
@import "@/styles/common.scss";
@include badge-styles;
.stage-dropdown {
  .btn.disabled,
  .btn:disabled {
    opacity: 100%;
  }
  ul {
    padding: 0;
    li {
      font-size: 13px !important;
      a.dropdown-item {
        padding: 0.3rem 0.5rem;
      }
    }
  }
  svg {
    margin-left: 0.35rem;
  }
  .reducer-badge {
    border: none;
    font-weight: 300 !important;
    color: $color-font-headings !important;
  }
  .stage-colour-orange {
    background: #fe9a2b94 !important;
    border-color: #ec8c1e !important;
  }
  .stage-colour-yellow {
    background: #fff143ad !important;
    border-color: #dfdf34 !important;
  }
  .stage-colour-green {
    color: rgb(0, 102, 68) !important;
    background-color: #20e73b4f !important;
  }
  .stage-colour-lightBlue {
    color: rgb(7, 71, 166) !important;
    background-color: rgb(222, 235, 255) !important;
  }
  .stage-colour-blue {
    color: white !important;
    background-color: #547bb7 !important;
  }
  .stage-colour-pink {
    color: white !important;
    background-color: #e71be382 !important;
  }
  .stage-colour-darkRed {
    color: white !important;
    background-color: #842112bd !important;
  }
  .stage-colour-black {
    color: white !important;
    background-color: #000000b0 !important;
  }
  .stage-colour-darkGreen {
    color: white !important;
    background-color: #0f6e1bb9 !important;
  }
  .stage-colour-red {
    color: white !important;
    background-color: #d51d1da6 !important;
  }
}
</style>
