<template>
  <div>
    <b-img
      :title="source"
      width="30px"
      height="30px"
      v-if="source == 'Reducer'"
      class="icon"
      rounded="circle"
      :src="require('@/assets/images/reducer_pink.png')"
    />
    <b-img
      :title="source"
      width="30px"
      height="30px"
      v-if="source == 'Xero'"
      class="icon"
      rounded="circle"
      :src="require('@/assets/images/xero.png')"
    />
    <b-img
      :title="source"
      width="30px"
      height="30px"
      v-if="source == 'QuickBooks'"
      class="icon"
      rounded="circle"
      :src="require('@/assets/images/qb.png')"
    />
    <b-img
      :title="source"
      width="30px"
      height="30px"
      v-if="source == 'ArmstrongWatson'"
      class="icon"
      rounded="circle"
      :src="require('@/assets/images/armstrongwatson.png')"
    />
  </div>
</template>

<script>
export default {
  name: "OrganisationSourceIcon",
  props: {
    source: {
      type: String,
      required: true
    }
  }
};
</script>
