/**
 * This class is used for storing types from backend application
 */
import FormatHelper from "@/helper/formathelper";
class TypeHelper {
  static spotlightSectors = [
    "Energy",
    "Water",
    "Card Processing",
    "Telecoms",
    "Waste",
    "Fuel",
    "Hosting",
    "Marketing",
    "Recruitment",
    "Council",
    "Low Confidence"
  ];

  static salesRecordStages = [
    "PROSPECTING_REQUIRED",
    "NEW_LEADS",
    "SHARED",
    "SPOKEN_TO",
    "QUOTING",
    "CONSULTATION_BOOKED",
    "FOLLOW_UP_BOOKED",
    "SIGNING_BOOKED",
    "LEAVE_FOR_EXTENDED_PERIOD",
    "FLAG",
    "CLOSED_WON",
    "CLOSED_LOST",
    "POST_SALES",
    "GET_A_REVIEW"
  ];

  static formatSalesRecordStage(stage) {
    if (!stage) return "";
    switch (stage) {
      case "PROSPECTING_REQUIRED":
        return "0. Prospecting required";
      case "NEW_LEADS":
        return "1. New lead";
      case "SHARED":
        return "2. Email sent";
      case "SPOKEN_TO":
        return "3. Spoken to";
      case "QUOTING":
        return "4. Quoting";
      case "CONSULTATION_BOOKED":
        return "5. Consultation";
      case "FOLLOW_UP_BOOKED":
        return "6. Follow up";
      case "SIGNING_BOOKED":
        return "7. Signing";
      case "LEAVE_FOR_EXTENDED_PERIOD":
        return "8. Leave for an extended period";
      case "FLAG":
        return "9. Flag";
      case "CLOSED_WON":
        return "10. Closed won";
      case "CLOSED_LOST":
        return "11. Closed lost";
      case "POST_SALES":
        return "12. Post sales";
      case "GET_A_REVIEW":
        return "13. Get a review";
      default:
        return FormatHelper.formatCoboldCase(stage);
    }
  }

  static salesRecordStageColourMap = {
    NEW_LEADS: "grey",
    SHARED: "orange",
    SPOKEN_TO: "yellow",
    QUOTING: "green",
    CONSULTATION_BOOKED: "lightBlue",
    FOLLOW_UP_BOOKED: "blue",
    SIGNING_BOOKED: "pink",
    LEAVE_FOR_EXTENDED_PERIOD: "darkRed",
    FLAG: "black",
    CLOSED_WON: "darkGreen",
    CLOSED_LOST: "red"
  };

  static noteTypes = [
    "INTRODUCTION",
    "FOLLOW_UP",
    "CONSULTATION",
    "EMAIL",
    "MEETING",
    "PHONE_CALL",
    "SOCIAL"
  ];
}
export default TypeHelper;
