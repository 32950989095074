<template>
  <b-form-input type="date" :size="size" :value="formattedDate" @change="formatDateToISO" />
</template>

<script>
import FormatHelper from "@/helper/formathelper";
export default {
  name: "DateInuput",
  model: {
    prop: "date",
    event: "change"
  },
  props: {
    date: String,
    size: String
  },
  computed: {
    formattedDate() {
      return FormatHelper.formatDateTimeCustom(this.date, "YYYY-MM-DD");
    }
  },
  methods: {
    formatDateToISO(date) {
      this.$emit("change", date ? date + "T00:00:00.000" : date);
    }
  }
};
</script>
