<template>
  <b-dropdown
    variant="link"
    toggle-class="text-decoration-none p-0"
    no-caret
    class="closed-lost-reason-select"
  >
    <template #button-content>
      <b-badge class="selection-badge reducer-badge">
        {{ closedLostReason ? closedLostReason : "Select closed lost reason"
        }}<font-awesome-icon :icon="['fas', 'chevron-down']" />
      </b-badge>
    </template>
    <b-dropdown-item v-for="r in closedLostReasons" :key="r" @click="changeReason(r)">
      {{ r }}
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
export default {
  name: "ClosedLostReasonSelect",
  props: {
    closedLostReason: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      closedLostReasons: [
        "Accountant asked to stop contacting",
        "Already renewed with broker",
        "Already renewed with supplier",
        "Business closing",
        "Credit too low",
        // "Doesn't think savings are high enough",
        "Everything managed by the landlord",
        // "Found a cheaper deal with broker",
        // "Found a cheaper deal with supplier",
        // "Found out still in contract",
        // "Happy with current provider",
        "Just went cold",
        "Never been able to find number",
        "Never been able to get hold of contact",
        "Not interested - did not let me pitch",
        "Not interested - let me pitch",
        "Report information was incorrect"
      ]
    };
  },
  methods: {
    changeReason(closedLostReason) {
      if (this.closedLostReason !== closedLostReason) {
        this.$emit("change-reason", closedLostReason);
      }
    }
  }
};
</script>

<style lang="scss">
.closed-lost-reason-select {
  ul {
    padding: 0;
    li {
      font-size: 13px !important;
      a.dropdown-item {
        padding: 0.3rem 0.5rem;
      }
    }
  }
  svg {
    margin-left: 0.35rem;
  }
  .selection-badge {
    color: #cf2d22f5;
    background: #ee30001f;
    border-color: #b91d1d3b !important;
  }
}
</style>
