<template>
  <div id="sales-organisations-tab">
    <!-- ORGANISATIONS FILTER -->
    <b-form ref="filter" class="reducer-filter mb-3" @submit.prevent="validateAndRunQuery(false)">
      <b-row>
        <b-col cols="12" md="6">
          <b-row>
            <b-col cols="12" md="6" class="mt-2 mt-md-0">
              <b-form-group label="FROM LAST EXTRACT" label-for="startDate">
                <b-input
                  id="startDate"
                  v-model="filter.startDate"
                  class="reducer-input"
                  placeholder="YYYY-MM"
                  :disabled="busy"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12" md="6" class="mt-2 mt-md-0">
              <b-form-group label="TO LAST EXTRACT" label-for="endDate">
                <b-input
                  id="endDate"
                  class="reducer-input"
                  v-model="filter.endDate"
                  placeholder="YYYY-MM"
                  :disabled="busy"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12" class="d-flex">
              <b-form-checkbox
                class="mr-3"
                :disabled="busy"
                v-model="filter.showReportComplete"
                switch
                >REPORT COMPLETE ONLY</b-form-checkbox
              >
            </b-col>
          </b-row>
        </b-col>
        <b-col class="mt-2 mt-md-0">
          <b-form-group label-for="queryInput" label="SEARCH QUERY">
            <b-input-group>
              <b-form-input
                id="queryInput"
                v-model="filter.queryInput"
                :disabled="busy"
                class="reducer-input"
                placeholder="Organisation name / user email"
                @keydown.native.enter="validateAndRunQuery(false)"
              />
              <b-input-group-append>
                <b-btn :disabled="busy" variant="primary" class="reducer-btn" type="submit"
                  >Submit</b-btn
                >
                <b-btn :disabled="busy" @click="clearFilter">Clear</b-btn>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
          <div class="text-right mt-2">
            <b-badge class="pointer underline--hover mr-2" @click="setExtractInputs(1)"
              >LAST 2 MONTHS</b-badge
            >
            <b-badge class="pointer underline--hover" @click="setExtractInputs(0)"
              >THIS MONTH</b-badge
            >
          </div>
        </b-col>
        <b-col cols="12">
          <b-form-invalid-feedback :state="datesErrorMessage == null">
            {{ datesErrorMessage }}
          </b-form-invalid-feedback>
        </b-col>
        <b-col cols="12">
          <p
            class="small text-center mb-0 pointer"
            style="margin-top: -15px;"
            v-b-toggle.extra-filters
          >
            View more filters
            <font-awesome-icon :icon="['fas', 'arrow-circle-down']" />
          </p>
        </b-col>
        <b-collapse id="extra-filters" class="w-100">
          <div class="d-flex filter-border mt-3">
            <!-- CED date filter -->
            <b-col cols="6" md="6" lg="2" class="date-selection">
              <b-form-group label="CONTRACT ENDS FROM" label-for="cedStart">
                <b-form-input
                  v-model="filter.cedStart"
                  id="cedStart"
                  type="date"
                  class="blue-border"
                />
                <div class="date-btns d-none">
                  <b-badge class="pointer underline--hover" @click="setCed(0, 'days')">
                    TODAY (OOC)
                  </b-badge>
                  <b-badge class="ml-2 pointer underline--hover" @click="setCed(0)">
                    THIS MONTH
                  </b-badge>
                  <b-badge class="ml-2 pointer underline--hover" @click="setCed(6, 'weeks')">
                    NEXT 6 WEEKS
                  </b-badge>
                  <b-badge class="ml-2 pointer underline--hover" @click="setCed(3)">
                    NEXT 3 MONTHS
                  </b-badge>
                </div>
              </b-form-group>
            </b-col>

            <b-col cols="6" md="6" lg="2">
              <b-form-group label="CONTRACT ENDS TO" label-for="cedEnd">
                <b-form-input v-model="filter.cedEnd" id="cedEnd" type="date" />
              </b-form-group>
            </b-col>
          </div>
        </b-collapse>
      </b-row>
    </b-form>

    <div class="d-flex justify-content-between">
      <span v-if="filteredOrganisations.length > 1" class="text-muted float-left"
        >{{ filteredOrganisations.length }} Deal(s)
        {{
          bulkEditingEnabled && allSelectedRows.length ? `- ${allSelectedRows.length} selected` : ""
        }}</span
      >
      <b-button
        v-if="filteredOrganisations.length"
        class="reducer-btn border"
        size="sm"
        :variant="bulkEditingEnabled ? 'danger' : 'light'"
        @click="toggleBulkEdit"
        >{{ !bulkEditingEnabled ? "" : "Cancel" }} Bulk Edit</b-button
      >
    </div>
    <div v-if="bulkEditingEnabled" class="d-flex">
      <b-button class="reducer-btn border" variant="light" size="sm" @click="selectAllRows"
        >Select all</b-button
      >
      <b-button class="reducer-btn border ml-2" variant="light" size="sm" @click="clearSelected"
        >Clear selected</b-button
      >
      <b-button
        class="reducer-btn border ml-2"
        variant="primary"
        size="sm"
        @click="$emit('bulk-edit', allSelectedRows)"
        :disabled="!allSelectedRows.length"
        >Edit selected</b-button
      >
    </div>
    <b-overlay :show="tableBusy" spinner-variant="primary" spinner-type="grow" no-center>
      <Busy v-if="busy" primary text="Fetching deals..." />

      <!-- ORGANISATIONS TABLE -->
      <b-table
        v-else
        class="mt-1"
        ref="recordsTable"
        :items="filteredOrganisations"
        :fields="tableFields"
        show-empty
        empty-text="No deals found"
        :per-page="perPage"
        :current-page="currentPage"
        selectable
        :selected-variant="bulkEditingEnabled ? 'active' : 'none'"
        @row-selected="onRowSelected"
      >
        <template v-if="bulkEditingEnabled" #cell(selected)="{ rowSelected }">
          <b-check class="mt-2" :checked="rowSelected" disabled />
        </template>

        <template #cell(organisationId)="row">
          <b-dropdown class="mt-2" lazy size="sm" variant="outline-secondary">
            <organisation-tabs
              :organisation-id="row.value"
              :archived="row.item.status == 'ARCHIVED'"
              :links="[
                'organisation-extracts',
                'organisation-details',
                'savings',
                'savings-editor'
              ]"
            />
            <b-dropdown-divider />
            <b-dropdown-item @click="openCpmModal(row.item)">
              <font-awesome-icon :icon="['fas', 'user']" class="text-muted mr-1 pr-1" />
              Assign CPM
            </b-dropdown-item>
          </b-dropdown>
        </template>

        <template #cell(organisationSource)="row">
          <OrganisationSourceIcon :source="row.value" />
        </template>

        <template #cell(state)="row">
          <b-badge :variant="row.value == 'Report complete' ? 'success' : ''">{{
            row.value
          }}</b-badge>
        </template>
      </b-table>
    </b-overlay>

    <b-pagination
      v-if="filteredOrganisations.length > perPage"
      align="right"
      :total-rows="filteredOrganisations.length"
      :per-page="perPage"
      v-model="currentPage"
    />

    <AssignCpmModal
      ref="cpmModal"
      :cpms="cpms"
      :apms="apms"
      :users-busy="usersBusy"
      @org-assigned="$emit('org-assigned', $event)"
    />

    <b-modal
      lazy
      id="all-orgs-warning"
      hide-header
      @ok="validateAndRunQuery(true)"
      ok-title="Just do it already"
      cancel-title="Abort mission"
    >
      <div class="m-3">
        <h5>
          Woah there friend, that query gets every organisation. That's a lot of data. Your query
          should probably be more specific.
        </h5>
      </div>
    </b-modal>
  </div>
</template>

<script>
import FormatHelper from "@/helper/formathelper";
import Busy from "@/components/Busy";
import OrganisationSourceIcon from "@/components/OrganisationSourceIcon";
import OrganisationTabs from "@/components/nav/OrganisationTabs";
import moment from "moment";
import AssignCpmModal from "@/components/sales/AssignCpmModal";

export default {
  name: "SalesOrganisationsTab",
  components: {
    Busy,
    OrganisationSourceIcon,
    OrganisationTabs,
    AssignCpmModal
  },
  props: {
    organisations: {
      type: Array,
      required: true
    },
    busy: {
      type: Boolean,
      required: true
    },
    cpms: {
      type: Array,
      required: true
    },
    apms: {
      type: Array,
      required: true
    },
    // fetching cpm data
    usersBusy: {
      type: Boolean,
      required: true
    },
    tableBusy: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      datesErrorMessage: null,
      perPage: 50,
      currentPage: 1,
      bulkEditingEnabled: false,
      selectedRows: [],
      selectedRowsHistory: {},

      filter: {
        startDate: null,
        endDate: null,
        queryInput: null,
        showReportComplete: false,
        cedStart: null,
        cedEnd: null
      },

      tableFields: [
        { key: "selected", label: "" },
        { key: "organisationId", label: "" },
        { key: "name", formatter: FormatHelper.orgShortName, sortable: true },
        { key: "firstUserEmail", label: "First User", sortable: true },
        {
          key: "lastExtractJob",
          label: "Last Extract",
          formatter: FormatHelper.formatDate,
          sortable: true
        },
        { key: "organisationSource", label: "" },
        {
          key: "saving.totalCommission",
          label: "Revenue",
          formatter: FormatHelper.formatPoundToDisplay,
          sortable: true
        },
        {
          key: "saving.totalSavings",
          label: "Savings",
          formatter: FormatHelper.formatPoundToDisplay,
          sortable: true
        },
        {
          key: "state",
          label: "Status",
          formatter: FormatHelper.formatSavingsState,
          sortable: true
        }
      ]
    };
  },
  watch: {
    currentPage: {
      async handler(newPage, oldPage) {
        // Record page selection history
        this.selectedRowsHistory[oldPage] = [...this.selectedRows];
        // Select historic rows
        if (this.selectedRowsHistory[newPage]) {
          await this.$nextTick();
          const startIndex = (newPage - 1) * this.perPage;
          const paginatedIds = this.filteredOrganisationsIds.slice(
            startIndex,
            startIndex + this.perPage
          );
          this.selectedRowsHistory[newPage].forEach(r => {
            const rowIndex = paginatedIds.indexOf(r.organisationId);
            this.$refs.recordsTable.selectRow(rowIndex);
          });
        }
      }
    }
  },
  computed: {
    filteredOrganisations() {
      let orgs = this.organisations;
      if (this.filter.showReportComplete && orgs) {
        orgs = orgs.filter(org => org.state == "REPORT_COMPLETE");
      }
      return orgs;
    },
    filteredOrganisationsIds() {
      return this.filteredOrganisations.map(s => s.organisationId);
    },
    allSelectedRows() {
      const hasHistory = Object.keys(this.selectedRowsHistory).length;
      if (hasHistory) {
        const upToDateSelected = {
          ...this.selectedRowsHistory,
          [this.currentPage]: this.selectedRows
        };
        return Object.values(upToDateSelected).flat();
      }
      return this.selectedRows;
    }
  },
  methods: {
    openCpmModal(org) {
      this.$refs.cpmModal.show(org);
    },

    validateAndRunQuery(allOrgs) {
      this.datesErrorMessage = null;
      var today = new Date()
        .toJSON()
        .slice(0, 7)
        .replace(/-/g, "-");

      if (!allOrgs && !this.filter.endDate && !this.filter.startDate && !this.filter.queryInput) {
        this.$bvModal.show("all-orgs-warning");
        return;
      }
      if (this.filter.endDate || this.filter.startDate) {
        if (
          (this.filter.startDate && !moment(this.filter.startDate, "YYYY-MM", true).isValid()) ||
          (this.filter.endDate && !moment(this.filter.endDate, "YYYY-MM", true).isValid())
        ) {
          this.datesErrorMessage = "Enter a valid Start and End date";
          return;
        } else if (
          this.filter.endDate &&
          this.filter.startDate &&
          moment(this.filter.startDate, "YYYY-MM") > moment(this.filter.endDate, "YYYY-MM")
        ) {
          this.datesErrorMessage = "Start date must be equal or earlier than end date";
          return;
        } else if (this.filter.startDate > today) {
          this.datesErrorMessage = "Start date cannot be in the future";
          return;
        }
      }

      this.$emit("fetch-organisations", this.filter);
    },

    setExtractInputs(monthsBack) {
      if (!this.busy) {
        var today = new Date();
        this.filter.endDate = today.toISOString().slice(0, 7);
        today.setMonth(today.getMonth() - monthsBack);
        this.filter.startDate = today.toISOString().slice(0, 7);
      }
    },

    toggleBulkEdit() {
      this.clearSelected();
      this.bulkEditingEnabled = !this.bulkEditingEnabled;
    },

    onRowSelected(rows) {
      this.selectedRows = rows;
    },

    async selectAllRows() {
      this.tableBusy = true;
      const pagesCount = Math.ceil(this.filteredOrganisations.length / this.perPage);
      for (let i = 1; i <= pagesCount; i++) {
        this.currentPage = i;
        await this.$nextTick();
        this.$refs.recordsTable.selectAllRows();
        await this.$nextTick();
      }
      this.currentPage = 1;
      this.tableBusy = false;
    },

    clearSelected() {
      this.$refs.recordsTable.clearSelected();
      this.selectedRowsHistory = {};
    },

    selectRow(i) {
      this.$refs.recordsTable.selectRow(i);
    },

    clearFilter() {
      this.filter = {
        startDate: null,
        endDate: null,
        queryInput: null,
        showReportComplete: true,
        cedStart: null,
        cedEnd: null
      };
    },

    setCed(timeForward, unit) {
      this.setTimeForward("cedStart", "cedEnd", timeForward, unit);
    },

    setTimeForward(startDateField, endDateField, timeForward, unit = "months") {
      if (timeForward == 0) {
        this.filter[startDateField] = moment()
          .startOf(unit)
          .format("YYYY-MM-DD");
        this.filter[endDateField] = moment()
          .endOf(unit)
          .format("YYYY-MM-DD");
      } else {
        this.filter[endDateField] = moment()
          .add(timeForward, unit)
          .format("YYYY-MM-DD");

        this.filter[startDateField] = moment().format(
          timeForward == 1 ? "YYYY-MM-01" : "YYYY-MM-DD"
        );
      }
    }
  }
};
</script>

<style lang="scss" scoped>
#sales-organisations-tab {
  tr {
    cursor: unset;
  }
  .spinner-grow {
    position: absolute;
    left: 50%;
    top: 12rem;
  }
}
</style>
