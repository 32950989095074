<template>
  <div id="sales-records-tab">
    <!-- DEALS FILTER -->
    <b-form
      ref="filter"
      class="reducer-filter mb-3"
      @submit.prevent="fetchSalesRecords"
      @input="updateUrl"
    >
      <b-row>
        <!-- CPM email -->
        <b-col cols="12" md="3" class="mt-2 mt-md-0">
          <b-form-group label="CPMs" label-for="cpm">
            <b-select
              id="cpm"
              v-model="filter.cpmId"
              :options="cpmSelectOptions"
              value-field="userId"
              placeholder="Select CPM"
              :disabled="usersBusy || organisationIdsInitialQuery"
              @change="checkShouldIncludeCommission"
            >
              <template #first>
                <b-form-select-option :value="null">Show all CPMs</b-form-select-option>
              </template>
            </b-select>
          </b-form-group>
        </b-col>

        <!-- OPS email -->
        <b-col cols="12" md="3" class="mt-2 mt-md-0">
          <b-form-group label="OPS" label-for="ops">
            <b-select
              id="ops"
              v-model="filter.opsId"
              :options="opsSelectOptions"
              value-field="userId"
              placeholder="Select Ops"
              :disabled="usersBusy || organisationIdsInitialQuery"
              @change="checkShouldIncludeCommission"
            >
              <template #first>
                <b-form-select-option :value="null">Show all Ops</b-form-select-option>
              </template>
            </b-select>
          </b-form-group>
        </b-col>

        <!-- Query -->
        <b-col cols="12" md="6">
          <b-form-group
            :label="`QUERY${!filter.cpmId && !filter.opsId ? ' • case sensitive' : ''}`"
            label-for="query"
          >
            <b-input-group>
              <b-form-input
                v-model="filter.query"
                id="query"
                placeholder="Organisation name / accountant email / APM email / project"
              />
              <b-input-group-append>
                <b-button type="submit" class="reducer-btn" variant="primary">Submit</b-button>
                <b-button class="reducer-btn" @click="resetFilter">Clear</b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <p class="small text-center mb-0 pointer" v-b-toggle.extra-filters>
            View more filters
            <font-awesome-icon :icon="['fas', 'arrow-circle-down']" />
          </p>
        </b-col>

        <b-collapse id="extra-filters" class="w-100">
          <div class="d-flex filter-border mt-2 flex-wrap">
            <!-- Report shared date filter -->
            <b-col cols="6" md="6" lg="2" class="date-selection">
              <b-form-group label="REPORT SHARED FROM" label-for="reportSharedStart">
                <b-form-input
                  v-model="filter.reportSharedStart"
                  id="reportSharedStart"
                  type="date"
                  class="blue-border"
                  :disabled="!filter.includeCommission"
                />
                <div v-if="filter.includeCommission" class="date-btns d-none">
                  <b-badge class="pointer underline--hover" @click="setSharedDate(0)">
                    THIS MONTH
                  </b-badge>
                  <b-badge class="ml-2 pointer underline--hover" @click="setSharedDate(2)">
                    LAST 2 MONTHS
                  </b-badge>
                </div>
              </b-form-group>
            </b-col>

            <b-col cols="6" md="6" lg="2">
              <b-form-group label="REPORT SHARED TO" label-for="reportSharedEnd">
                <b-form-input
                  v-model="filter.reportSharedEnd"
                  id="reportSharedEnd"
                  type="date"
                  :disabled="!filter.includeCommission"
                />
              </b-form-group>
            </b-col>

            <!-- CED date filter -->
            <b-col cols="6" md="6" lg="2" class="date-selection">
              <b-form-group label="CONTRACT ENDS FROM" label-for="cedStart">
                <b-form-input
                  v-model="filter.cedStart"
                  id="cedStart"
                  type="date"
                  class="blue-border"
                  :disabled="!filter.includeCommission"
                />
                <div v-if="filter.includeCommission" class="date-btns d-none">
                  <b-badge class="pointer underline--hover" @click="setCed(0, 'days')">
                    TODAY (OOC)
                  </b-badge>
                  <b-badge class="ml-2 pointer underline--hover" @click="setCed(0)">
                    THIS MONTH
                  </b-badge>
                  <b-badge class="ml-2 pointer underline--hover" @click="setCed(6, 'weeks')">
                    NEXT 6 WEEKS
                  </b-badge>
                  <b-badge class="ml-2 pointer underline--hover" @click="setCed(3)">
                    NEXT 3 MONTHS
                  </b-badge>
                </div>
              </b-form-group>
            </b-col>

            <b-col cols="6" md="6" lg="2">
              <b-form-group label="CONTRACT ENDS TO" label-for="cedEnd">
                <b-form-input
                  v-model="filter.cedEnd"
                  id="cedEnd"
                  type="date"
                  :disabled="!filter.includeCommission"
                />
              </b-form-group>
            </b-col>

            <!-- Next Action Date filter -->
            <b-col cols="6" md="6" lg="2" class="date-selection">
              <b-form-group label="NEXT ACTION FROM" label-for="nextActionStart">
                <b-form-input
                  v-model="filter.nextActionStart"
                  id="nextActionStart"
                  type="date"
                  class="blue-border"
                />
                <div class="date-btns d-none">
                  <b-badge class="pointer underline--hover" @click="setNextAction(0, 'days')">
                    TODAY (OOC)
                  </b-badge>
                  <b-badge class="ml-2 pointer underline--hover" @click="setNextAction(0)">
                    THIS MONTH
                  </b-badge>
                  <b-badge class="ml-2 pointer underline--hover" @click="setNextAction(6, 'weeks')">
                    NEXT 6 WEEKS
                  </b-badge>
                  <b-badge class="ml-2 pointer underline--hover" @click="setNextAction(3)">
                    NEXT 3 MONTHS
                  </b-badge>
                </div>
              </b-form-group>
            </b-col>

            <b-col cols="6" md="6" lg="2">
              <b-form-group label="NEXT ACTION TO" label-for="nextActionEnd">
                <b-form-input v-model="filter.nextActionEnd" id="nextActionEnd" type="date" />
              </b-form-group>
            </b-col>

            <!-- Max/min commission filters -->
            <b-col cols="6" md="6" lg="2">
              <b-form-group label="MINIMUM REVENUE" label-for="minCommission">
                <b-form-input
                  v-model="filter.minCommission"
                  id="minCommission"
                  class="green-border"
                  type="number"
                  min="0"
                  :disabled="!filter.includeCommission"
                />
              </b-form-group>
            </b-col>
            <b-col cols="6" md="6" lg="2">
              <b-form-group label="MAXIMUM REVENUE" label-for="maxCommission">
                <b-form-input
                  v-model="filter.maxCommission"
                  id="maxCommission"
                  type="number"
                  min="0"
                  :disabled="!filter.includeCommission"
                />
              </b-form-group>
            </b-col>

            <!-- Saving area filter -->
            <b-col cols="12" md="4">
              <b-form-group label="AREAS WITH REVENUE" label-for="saving-areas">
                <multiselect
                  v-model="filter.areasWithCommission"
                  :options="supplyTypes"
                  multiple
                  placeholder="Select saving areas"
                  :customLabel="formatArea"
                  id="saving-areas"
                  :disabled="!filter.includeCommission"
                />
              </b-form-group>
            </b-col>

            <!-- Sales record stage filter -->
            <b-col cols="12" md="4">
              <span class="pointer underline small float-right" @click="clearStagesFilter"
                >CLEAR ALL</span
              >
              <b-form-group label="EXCLUDE STAGES" label-for="stages">
                <multiselect
                  v-model="filter.excludeStages"
                  :options="stages"
                  multiple
                  placeholder="Select stages"
                  :customLabel="formatStage"
                  id="stages"
                  :close-on-select="false"
                />
              </b-form-group>
            </b-col>

            <div class="d-flex pl-3">
              <b-check v-model="filter.includeCommission" class="mr-3"
                >Show commission and revenue
                <font-awesome-icon
                  class="menu-icon"
                  :icon="['far', 'question-circle']"
                  v-b-popover.hover="'Fetching savings can slow down your query significantly'"
              /></b-check>
              <b-check
                v-model="filter.includeTotalAq"
                :disabled="!filter.includeCommission"
                class="mr-3"
                >Show total AQ</b-check
              >
              <b-check
                v-model="filter.includeSoonestCed"
                :disabled="!filter.includeCommission"
                class="mr-3"
                >Show soonest CED</b-check
              >
              <b-check v-model="filter.deletedOrgsOnly" class="mr-3"
                >Show deleted organisations only</b-check
              >
            </div>
          </div>
        </b-collapse>
      </b-row>
    </b-form>

    <Busy v-if="busy && !salesRecords.length" primary text="Fetching deals..." />

    <!-- DEALS TABLE -->
    <div v-else>
      <div class="d-flex justify-content-between">
        <div class="d-flex flex-column">
          <span v-if="organisationIdsInitialQuery" class="text-muted float-left"
            >Querying {{ organisationIdsInitialQuery.length }} organisations from Expiring
            Deals</span
          >
          <span v-if="computedSalesRecords.length > 1" class="text-muted float-left"
            >{{ computedSalesRecords.length }} Deals
            {{
              filter.includeCommission && totalCommission
                ? ` • Total commission: ${formatPound(totalCommission)}`
                : ""
            }}
            {{ filter.includeTotalAq && totalAQ ? ` • Total AQ: ${round(totalAQ)}` : "" }}
            {{
              bulkEditingEnabled && allSelectedRows.length
                ? ` • ${allSelectedRows.length} deal(s) selected`
                : ""
            }}</span
          >
        </div>
        <b-button
          v-if="salesRecords.length"
          class="reducer-btn border mb-2"
          size="sm"
          :variant="bulkEditingEnabled ? 'danger' : 'light'"
          @click="toggleBulkEdit"
          >{{ !bulkEditingEnabled ? "" : "Cancel" }} Bulk Edit</b-button
        >
      </div>
      <div v-if="bulkEditingEnabled" class="d-flex">
        <b-button class="reducer-btn border" variant="light" size="sm" @click="selectAllRows"
          >Select all</b-button
        >
        <b-button class="reducer-btn border ml-2" variant="light" size="sm" @click="clearSelected"
          >Clear selected</b-button
        >
        <b-button
          class="reducer-btn border ml-2"
          variant="primary"
          size="sm"
          @click="$emit('bulk-edit', allSelectedRows)"
          :disabled="!allSelectedRows.length"
          >Edit selected</b-button
        >
      </div>
      <b-overlay :show="tableBusy" spinner-variant="primary" spinner-type="grow" no-center>
        <b-table
          class="mt-1"
          ref="recordsTable"
          :items="computedSalesRecords"
          :fields="visibleTableFields"
          :per-page="perPage"
          :current-page="currentPage"
          sort-desc-first
          selectable
          @row-selected="onRowSelected"
          :selected-variant="bulkEditingEnabled ? 'active' : 'none'"
        >
          <template v-if="bulkEditingEnabled" #cell(selected)="{ rowSelected }">
            <b-check class="mt-2" :checked="rowSelected" disabled />
          </template>

          <template #cell(organisationId)="row">
            <b-dropdown class="mt-2" lazy size="sm" variant="outline-secondary">
              <organisation-tabs
                v-if="row.item.firstUserEmail"
                :organisation-id="row.value"
                :archived="row.item.status == 'ARCHIVED'"
                :links="['textract-result', 'organisation-details', 'savings', 'savings-editor']"
              />
              <b-dropdown-divider v-if="row.item.firstUserEmail" />
              <b-dropdown-item
                :to="{
                  name: 'sales-record',
                  params: { organisationId: row.value }
                }"
                target="_blank"
              >
                <font-awesome-icon :icon="['fas', 'chart-network']" class="text-muted mr-2" />Deal
              </b-dropdown-item>
              <b-dropdown-item @click="showDeleteModal(row.item)">
                <font-awesome-icon :icon="['fas', 'trash']" class="text-muted mr-1 pr-1" />
                Delete Record
              </b-dropdown-item>
            </b-dropdown>
          </template>

          <template #cell(stageId)="row">
            <SalesRecordStageSelect
              :stage="row.item.stage"
              :closed-lost-reason="row.item.closedLostReason"
              @change-stage="changeStage(row.item, $event)"
              @change-closed-lost="updateClosedLost(row.item, $event)"
              @change-closed-won="updateClosedWon(row.item, $event)"
            />
          </template>

          <template #cell(organisationName)="row">
            <span>{{ row.value }}</span>
            <b-badge v-if="!row.item.firstUserEmail" variant="danger" class="p-1 ml-2"
              >Org Deleted</b-badge
            >
          </template>
        </b-table>
      </b-overlay>

      <b-pagination
        v-if="salesRecords.length > perPage"
        align="right"
        :total-rows="salesRecords.length"
        :per-page="perPage"
        v-model="currentPage"
      />
    </div>
    <b-modal
      v-model="deleteModal.show"
      @ok="deleteSalesRecord(deleteModal.organisationId)"
      title="Delete Deal"
    >
      Are you sure you want to delete deal for
      {{ deleteModal.orgName ? deleteModal.orgName : "this organisation" }}? This action will not
      delete organisation or report but will delete deal contact details and closed lost/won meter
      statuses.
    </b-modal>
    <b-modal
      lazy
      id="all-orgs-warning"
      hide-header
      @ok="fetchSalesRecords(true)"
      ok-title="Just do it already"
      cancel-title="Abort mission"
    >
      <div class="m-3">
        <h5>
          Woah there friend, that query gets every organisation. That's a lot of data. Your query
          should probably be more specific. <br /><br />Try excluding commission and revenue at the
          bottom of the filter.
        </h5>
      </div>
    </b-modal>
  </div>
</template>

<script>
import FormatHelper from "@/helper/formathelper";
import Busy from "@/components/Busy";
import OrganisationTabs from "@/components/nav/OrganisationTabs";
import TypeHelper from "@/helper/typehelper";
import ApiHelper from "@/helper/apihelper";
import { EventBus } from "@/components/eventbus";
import SalesRecordStageSelect from "@/components/sales/SalesRecordStageSelect";
import moment from "moment";
import SupplyTypes from "@/helper/supplytypehelper";

export default {
  name: "SalesRecordsTab",
  components: {
    Busy,
    OrganisationTabs,
    SalesRecordStageSelect
  },
  props: {
    salesRecords: {
      type: Array,
      required: true
    },
    busy: {
      type: Boolean,
      required: true
    },
    cpms: {
      type: Array,
      required: true
    },
    ops: {
      type: Array,
      required: true
    },
    usersBusy: {
      type: Boolean,
      required: true
    },
    currentUserId: {
      type: String,
      required: true
    },
    tableBusy: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      perPage: 50,
      currentPage: 1,
      stages: TypeHelper.salesRecordStages,
      supplyTypes: SupplyTypes.supplyTypes(),
      bulkEditingEnabled: false,
      selectedRows: [],
      selectedRowsHistory: {},
      organisationIdsInitialQuery: null,

      filter: {
        cpmId: null,
        apmId: null,
        opsId: null,
        excludeStages: ["CLOSED_LOST"],
        minCommission: null,
        maxCommission: null,
        reportSharedStart: null,
        reportSharedEnd: null,
        cedStart: null,
        cedEnd: null,
        nextActionStart: null,
        nextActionEnd: null,
        query: null,
        areasWithCommission: [],
        deletedOrgsOnly: false,
        includeSoonestCed: false,
        includeCommission: false,
        includeTotalAq: false,
        organisationIds: null
      },

      deleteModal: {
        show: false,
        orgName: null,
        organisationId: null
      }
    };
  },
  mounted() {
    this.checkShouldIncludeCommission();
    this.fetchQuery();
  },
  watch: {
    currentPage: {
      async handler(newPage, oldPage) {
        // Record page selection history
        this.selectedRowsHistory[oldPage] = [...this.selectedRows];
        // Select historic rows
        if (this.selectedRowsHistory[newPage]) {
          await this.$nextTick();
          const startIndex = (newPage - 1) * this.perPage;
          const paginatedIds = this.computedSalesRecordsIds.slice(
            startIndex,
            startIndex + this.perPage
          );
          this.selectedRowsHistory[newPage].forEach(r => {
            const rowIndex = paginatedIds.indexOf(r.organisationId);
            this.$refs.recordsTable.selectRow(rowIndex);
          });
        }
      }
    }
  },
  computed: {
    isCurrentUserCpm() {
      return this.cpms.find(cpm => cpm.userId == this.currentUserId && !cpm.apmId) != undefined;
    },
    computedSalesRecords() {
      let updatedSalesRecords = this.salesRecords;
      if (this.filter.deletedOrgsOnly) {
        updatedSalesRecords = updatedSalesRecords.filter(d => !d.organisation);
      }
      // Set stage ids for tables
      return updatedSalesRecords.map(s => {
        s.totalCommission = this.adjustedRevenue(s); // remove closed meter commission
        return {
          ...s,
          stageId: this.formatStage(s.stage).match(/[^.]*/)[0]
        };
      });
    },
    totalCommission() {
      let sum = 0;
      this.computedSalesRecords.forEach(s => (sum += s.totalCommission ? s.totalCommission : 0));
      return sum;
    },
    totalAQ() {
      let sum = 0;
      this.computedSalesRecords.forEach(s => (sum += s.totalAQ ? s.totalAQ : 0));
      return sum;
    },
    computedSalesRecordsIds() {
      return this.computedSalesRecords.map(s => s.organisationId);
    },
    cpmSelectOptions() {
      return this.cpms.map(c => ({ ...c, text: FormatHelper.displayNameFromEmail(c.email) }));
    },
    opsSelectOptions() {
      return this.ops.map(c => ({ ...c, text: FormatHelper.displayNameFromEmail(c.email) }));
    },
    allSelectedRows() {
      const hasHistory = Object.keys(this.selectedRowsHistory).length;
      if (hasHistory) {
        const upToDateSelected = {
          ...this.selectedRowsHistory,
          [this.currentPage]: this.selectedRows
        };
        return Object.values(upToDateSelected).flat();
      }
      return this.selectedRows;
    },
    visibleTableFields() {
      let tableFields = [
        { key: "selected", label: "" },
        { key: "organisationId", label: "" },
        {
          key: "organisationName",
          label: "Name",
          formatter: FormatHelper.orgShortName,
          sortable: true
        },
        { key: "project", label: "Project", sortable: true },
        {
          key: "cpmEmail",
          label: "CPM",
          formatter: FormatHelper.displayNameFromEmail,
          sortable: true
        },
        {
          key: "apmEmail",
          label: "APM",
          formatter: FormatHelper.displayNameFromEmail,
          sortable: true
        },
        {
          key: "opsEmail",
          label: "Ops",
          formatter: FormatHelper.displayNameFromEmail,
          sortable: true
        },
        {
          key: "totalCommission",
          label: "Revenue",
          formatter: this.formatPound,
          sortable: true,
          hidden: !this.filter.includeCommission
        },
        {
          key: "totalSavings",
          label: "Savings",
          formatter: this.formatPound,
          sortable: true,
          hidden: !this.filter.includeCommission
        },
        {
          key: "totalAQ",
          label: "Total AQ",
          formatter: Math.round,
          sortable: true,
          hidden: !this.filter.includeTotalAq
        },
        {
          key: "soonestCED",
          label: "Soonest CED",
          formatter: FormatHelper.formatDate,
          sortable: true,
          hidden: !this.filter.includeSoonestCed
        },
        { key: "firstUserEmail", label: "First User", sortable: true },
        {
          key: "nextActionDate",
          label: "Next Action",
          formatter: FormatHelper.formatDate,
          sortable: true
        },
        {
          key: "viewed",
          label: "Report View",
          formatter: FormatHelper.formatDate,
          sortable: true
        },
        {
          key: "closedLostDate",
          label: "Closed Lost",
          formatter: FormatHelper.formatDate,
          sortable: true,
          hidden: this.filter.excludeStages.includes("CLOSED_LOST")
        },
        {
          key: "closedWonDate",
          label: "Closed Won",
          formatter: FormatHelper.formatDate,
          sortable: true,
          hidden: this.filter.excludeStages.includes("CLOSED_WON")
        },
        {
          key: "stageId",
          label: "Stage",
          sortable: true
        }
      ];
      return tableFields.filter(field => !field.hidden);
    }
  },
  methods: {
    round: Math.round,
    formatPound: FormatHelper.formatPoundToDisplay,
    formatStage: TypeHelper.formatSalesRecordStage,
    formatArea: SupplyTypes.toDisplay,
    async updateSalesRecord(salesRecord) {
      this.salesRecordsBusy = true;
      const client = await ApiHelper.http();
      await client
        .post(`${ApiHelper.endPoint()}sales/records`, {
          salesRecords: [salesRecord]
        })
        .then(() => {
          salesRecord.version += 1;
          this.showMessage("Deal updated successfully.", "success");
        })
        .catch(e => {
          console.error(e);
          if (e.response.status == 409) {
            this.showMessage(
              "Someone else has updated the deal. Please refresh the page to see the latest version.",
              "warning"
            );
          } else {
            this.showMessage("There was an issue updating the deal.", "warning");
          }
        })
        .finally(() => (this.salesRecordsBusy = false));
    },

    fetchSalesRecords(ignoreWarning) {
      if (
        ignoreWarning !== true &&
        this.filter.includeCommission &&
        !this.filter.cpmId &&
        !this.filter.opsId &&
        !this.filter.query
      ) {
        this.$bvModal.show("all-orgs-warning");
        return;
      }
      this.currentPage = 1;
      this.$emit("fetch-sales-records", this.filter);
    },

    fetchQuery() {
      if (!this.salesRecords.length && !this.busy) {
        let routeCpmId = this.$route.params.cpmId;
        let organisationIds = this.$route.params.organisationIds;
        // let fetchQueryFilter = this.$route.query.salesRecordFilter != null;
        // let queryFilter = this.parseFilterQuery();

        // if (fetchQueryFilter) {
        //   if (!queryFilter) {
        //     this.showMessage("Invalid URL parameter.", "warning");
        //     this.resetFilter();
        //   } else {
        //     console.log("Fetching query filter");
        //     this.filter = queryFilter;
        //     this.fetchSalesRecords();
        //   }
        // } else

        if (routeCpmId) {
          console.log("Fetching CPM records");
          this.filter.cpmId = routeCpmId ? routeCpmId : this.currentUserId;
          this.fetchSalesRecords();
        } else if (organisationIds) {
          console.log("Fetching organisations from Expiring Deals");
          this.filter.organisationIds = this.organisationIdsInitialQuery = organisationIds;
          this.fetchSalesRecords();
        }
      }
    },

    deleteSalesRecord(organisationId) {
      this.$emit("delete-sales-record", organisationId);
    },

    changeStage(salesRecord, stage) {
      salesRecord.stage = stage;
      this.updateSalesRecord(salesRecord);
    },

    updateClosedLost(salesRecord, { date, reason }) {
      salesRecord.closedLostReason = reason;
      salesRecord.closedLostDate = date;
      this.updateSalesRecord(salesRecord);
    },

    updateClosedWon(salesRecord, { date }) {
      salesRecord.closedWonDate = date;
      this.updateSalesRecord(salesRecord);
    },

    clearStagesFilter() {
      this.filter.excludeStages = [];
      this.updateUrl();
    },

    resetFilter() {
      this.filter = {
        cpmId: null,
        apmId: null,
        excludeStages: ["CLOSED_WON", "CLOSED_LOST"],
        minCommission: null,
        maxCommission: null,
        reportSharedStart: null,
        reportSharedEnd: null,
        cedStart: null,
        cedEnd: null,
        nextActionStart: null,
        nextActionEnd: null,
        query: null,
        areasWithCommission: [],
        organisationIds: null
      };
      this.organisationIdsInitialQuery = null;
      this.$router.replace({ query: null });
    },

    showDeleteModal(r) {
      this.deleteModal.orgName = r.organisationName;
      this.deleteModal.organisationId = r.organisationId;
      this.deleteModal.show = true;
    },

    showMessage(message, variant) {
      EventBus.$emit("show-toast", { message: message, variant: variant });
    },

    setSharedDate(monthsBack) {
      this.filter.reportSharedStart = moment()
        .subtract(monthsBack, "months")
        .format(monthsBack ? "YYYY-MM-DD" : "YYYY-MM-01");

      this.filter.reportSharedEnd = moment().format("YYYY-MM-DD");
      this.updateUrl();
    },

    setTimeForward(startDateField, endDateField, timeForward, unit = "months") {
      if (timeForward == 0) {
        this.filter[startDateField] = moment()
          .startOf(unit)
          .format("YYYY-MM-DD");
        this.filter[endDateField] = moment()
          .endOf(unit)
          .format("YYYY-MM-DD");
      } else {
        this.filter[endDateField] = moment()
          .add(timeForward, unit)
          .format("YYYY-MM-DD");

        this.filter[startDateField] = moment().format(
          timeForward == 1 ? "YYYY-MM-01" : "YYYY-MM-DD"
        );
      }
    },

    setCed(timeForward, unit) {
      this.setTimeForward("cedStart", "cedEnd", timeForward, unit);
    },

    setNextAction(timeForward, unit) {
      this.setTimeForward("nextActionStart", "nextActionEnd", timeForward, unit);
    },

    async updateUrl() {
      console.log("Updating url");
      await this.$nextTick();
      this.$router.replace({
        query: {
          salesRecordFilter: JSON.stringify({
            ...this.filter,
            organisationIds: this.organisationIdsInitialQuery
          })
        }
      });
    },

    parseFilterQuery() {
      let query = null;
      if (this.$route.query.salesRecordFilter) {
        try {
          let parsed = JSON.parse(this.$route.query.salesRecordFilter);
          if (typeof parsed == "object") {
            query = parsed;
          }
        } catch (e) {
          console.log(e);
        }
      }
      return query;
    },

    toggleBulkEdit() {
      this.clearSelected();
      this.bulkEditingEnabled = !this.bulkEditingEnabled;
    },

    onRowSelected(rows) {
      this.selectedRows = rows;
    },

    async selectAllRows() {
      this.tableBusy = true;
      const pagesCount = Math.ceil(this.computedSalesRecords.length / this.perPage);
      for (let i = 1; i <= pagesCount; i++) {
        this.currentPage = i;
        await this.$nextTick();
        this.$refs.recordsTable.selectAllRows();
        await this.$nextTick();
      }
      this.currentPage = 1;
      this.tableBusy = false;
    },

    clearSelected() {
      this.$refs.recordsTable.clearSelected();
      this.selectedRowsHistory = {};
    },

    selectRow(i) {
      this.$refs.recordsTable.selectRow(i);
    },

    adjustedRevenue(salesRecord) {
      console.log(salesRecord);

      let revenue = salesRecord.totalCommission;
      if (
        revenue &&
        salesRecord.saving &&
        salesRecord.saving.supplyCommission &&
        (salesRecord.closedLostMeters || salesRecord.closedWonMeters)
      ) {
        const allClosedMeters = [
          ...(salesRecord.closedLostMeters ? salesRecord.closedLostMeters : []),
          ...(salesRecord.closedWonMeters ? salesRecord.closedWonMeters : [])
        ];
        allClosedMeters.forEach(m => {
          if (salesRecord.saving.supplyCommission[m]) {
            revenue -= salesRecord.saving.supplyCommission[m];
          }
        });
      }
      return revenue;
    },

    checkShouldIncludeCommission() {
      if (this.filter.cpmId || this.filter.opsId) {
        this.filter.includeCommission = true;
      } else {
        this.filter.includeCommission = false;
      }
    }
  }
};
</script>

<style lang="scss">
#sales-records-tab {
  @import "@/styles/common.scss";
  min-height: 70vh;
  .blue-border {
    border-left: 4px solid $color-blue-lighter1;
  }
  .green-border {
    border-left: 4px solid $color-bright-green;
  }
  .multiselect {
    margin-top: -2px;
  }
  td {
    vertical-align: middle;
  }
}
</style>
